<template>
  <div class="breadcrums">
        <div class="crum" v-for="link in nesting" :key="link">
            <router-link :to="{'name': link}">{{link}}</router-link><i class="bi-arrow-right"/>
        </div>            
    </div>
</template>

<script>
export default {
    props:{
        nesting: Array,
    }
}
</script>

<style scoped>
    .breadcrums{
		display: none;
	}    
    
    @media screen and (min-width: 1024px) {		
        .breadcrums{
            display: flex;
            color: var(--background);
			padding: 0 var(--padding1);
        }
        .crum{
            display: flex;
            align-items: center;
            margin-right: .5rem;
        }
		a{
			width: auto;
			margin-right: .5rem;
            color: var(--background);
            text-decoration: none;
            border-bottom: 2px solid var(--background);
            line-height: 1rem;
		}		   
        .crum:last-child>.bi-arrow-right{
            display: none !important;
        }
    }
     @media screen and (min-width: 1440px) {		
        .breadcrums{
			padding: 0 var(--padding2);
        }
     }
</style>