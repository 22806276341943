<template>
    <div class="procudures">
        <h2><i :class="procedure.icon"></i>{{procedure.category}}</h2>
        <div class="sub-category" v-for="service in procedure['sub-category']" :key="service.category">                    
            <div class="category-label" v-on:click="service.enabled = !service.enabled">
                <i :class="{'bi-dash-circle-fill': service.enabled, 'bi-plus-circle-fill': !service.enabled}"></i>
                <h3>{{service.category}}</h3>
                <div class="sub-price">{{service.price}}</div>
            </div>
            <div :class="{transition : service.enabled}" class="sub-service" v-for="sub in service['services']" :key="sub.service">
                <h4 :class="{transition : service.enabled}">{{sub.service}}</h4>
                <h4 :class="{transition : service.enabled}">{{sub.price}}</h4>
            </div>
            <div :class="{transition : service.enabled}" class="info">
                <p v-for="info in service['info']" :key="info">{{info}}</p>
            </div>                  
        </div>
        <div style="flex-direction: row;" v-for="subservice in procedure.services" :key="subservice.service" class="sub-category">
            <h3 style="padding-left: 2.5rem">{{subservice.service}}</h3>
            <div class="sub-price">{{subservice.price}}</div>
        </div>        
    </div>    
</template>

<script>
export default {
    props:{
        procedure: Object,
    }
}
</script>

<style scoped>
    .procudures{
    margin-bottom: 3rem;
    }
    .sub-category{
        display: flex;
        flex-direction: column;
        color: var(--primary);
    }
    .sub-category > h3{
        width: auto;
    }
        
    .sub-service{
        display: flex;
        height: 0px;
        background-color: var(--primary-variant);   
        opacity: 0;   
        transition: .3s;     
        pointer-events:none;        
    }
    .sub-service.transition{
        height: calc(100%);
        opacity: 1;
        background-color: var(--secondary-variant);
    }    
   
    .info{
        background-color: var(--primary);
        color: var(--background);
        opacity: 0;
        height: 0px;
        /*transition: height .3s ease, opacity .3s ease;*/
        pointer-events:none;
    }
    .info.transition{
        opacity: 1;
        height: fit-content;
    }
    .sub-service > h4{        
        width: 30%;        
        margin: 0;
        text-align: end;
        border-bottom: 2px solid var(--primary-variant);
    }
    .sub-service > h4:nth-child(odd){
        padding-left: 2.5rem !important;
        padding-right: 1rem;
        text-align: start;
        width: 70%;
    }
    
    .info > p{
        padding: 0 .5rem;
        margin: 0;
    }
    .sub-price{
        margin-left: auto;
        margin-right: .5rem;
        font-weight: 100;
        text-align: end;
        align-self: center;
    }
    h2 {
        color: var(--primary);
    }
    h3{
        margin: 0 !important;
        align-self: center;
    }
        .category-label{
        display: flex;
        border-bottom: 2px solid var(--primary);
        justify-content: flex-start;
    }
    [class^="bi-"]::before{
        font-size: 1.5rem;
        padding: .25rem .5rem 0 .5rem;
       
    }
    .bi-plus::before{
        font-weight: bold !important;
    }
    @media screen and (min-width: 1024px){   
        .sub-category:hover{
            background-color: var(--highlight);
        }
        .sub-service > h4{        
            width: 70%;        
            text-align: start;
        }
        .sub-service > h4:nth-child(odd), h3, .sub-category > h3{
            width: 30%;
        }
        .sub-price{
            margin: 0;
        }
    }
</style>