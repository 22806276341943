<template>
	<div class="container">
		<bread-crums :nesting="['Dental Care', 'Periodontics & Hygiene']"></bread-crums>
		<intro button-text="Contact Us" button-link="/contact" title="Periodontics & Hygiene" :content="paragraphs"/>
		<div class="pgcontent">
			<div v-for="card in cards" :key="card.title" class="card">
				<h2>{{card.title}}</h2>
				<p>{{card.content}}</p>
			</div>
      <div v-for="section in sections" :key="section.title">
        <p>{{ section }}</p>
      </div>
			<div class="table">
				<price-table :procedure="Procedures['categories'][1]"></price-table>
			</div>
		</div>      
	</div>
</template>

<script>
import Intro from '../components/Intro.vue'
import PriceTable from '../components/PriceTable.vue'
import  Procedures  from "../assets/json/procedures.json";
import BreadCrums from '../components/BreadCrums.vue';
export default {
	name: 'GeneralDentisty',
	components: {
		Intro,
		PriceTable,
		BreadCrums
	},
	data(){
		return{
			Procedures,
			paragraphs: [
					"This area of dentistry focuses on the treatment of gum disease, also referred to as periodontitis. We offer a range of hygienist appointments tailored to your gum condition and health, which may be adapted depending on how you respond to the treatment and what diagnosis your dentist has given.",
			],
			cards:[
			],
      sections:[
        "The dentist may also carry out the gum treatment you are recommended. We are motivated to educate our patients on optimal home regime habits, discuss risk factors and predisposition to gum disease, either in its early or more advanced stages. Our understanding of gum health and disease has certainly become more advanced in recent years, and it is understood to have systemic implications too. By targeting gum disease, we aim to stabilise and maintain the soft tissue and structures that support your teeth. This will prevent bad breath, bleeding and sore gums and loose teeth.",
        "Your dentist will be able to guide you along the most appropriate regime to look after your oral health. We follow the British Society of Periodontology’s (BSP) Guidelines very closely in order to ensure this. For more information click on this link to find further visual and information aids to assist with understanding the importance of looking after your oral and gum health.",
        "https://www.bsperio.org.uk/patients"
      ]
		}
	}
}
</script>

<style scoped>
	.pgcontent {
		background-color: #FFF;
		padding: 1.5rem;
	}
	h2{
		width: 95%;
		color: var(--primary)
	}
	@media screen and (min-width: 375px){
		.pgcontent{
			padding: 1.5rem 2.5rem;
		}
	}
	@media screen and (min-width: 768px){
		.pgcontent{
			padding: 1.5rem6rem;
		}	
	}
	@media screen and (min-width: 1024px) {
		
		.container{
			padding-top: 1rem;
		}
		.pgcontent{
			display: flex;
			flex-wrap: wrap;
			padding: 2rem var(--padding1);
			justify-content: center;
		}
		.card{
			max-width: 50%;			
		}
		p{
			width: 70%;
		}
		.table{
			width: 100%;
		}
		
	}
	@media screen and (min-width: 1440px) {		
		.pgcontent{
			padding: 2rem var(--padding2);
		}    
	}
	@media screen and (min-width: 1750px){
		.pgcontent{
			padding: 2rem 4rem;
		}    
	}
</style>